import '../css-build/main.css';
import { Elm } from '../elm/LandingSite/Main.elm';
import { langFromURL } from '../../node_modules/compound-components/src/js/sharedEth/utils';

window.addEventListener('load', function () {
  const url = new URL(window.location);

  const supportedNetworks = ['goerli', 'mainnet'];
  const desiredTargetNetwork = url.searchParams.get('target_network');
  let targetNetwork = process.env.LANDING_TARGET_NETWORK;
  if (supportedNetworks.includes(desiredTargetNetwork)) {
    targetNetwork = desiredTargetNetwork;
  }

  let buildHref = (href) => href;
  let stripHref = (href) => href;
  let mountPath = window.BUILD_MOUNT_PATH || process.env.MOUNT_PATH || '';
  if (mountPath) {
    let mountRegex = new RegExp(`^/${mountPath}`);

    stripHref = (href) => {
      let url = new URL(href);
      url.pathname = url.pathname.replace(mountRegex, '');
      return url.href;
    };

    buildHref = (href) => {
      let url = new URL(href);
      url.pathname = mountPath + url.pathname;
      return url.href;
    };
  }

  const app = Elm.LandingSite.Main.init({
    node: document.getElementById('root') || document.getElementById('main'), // no pre-rendered // pre-rendered
    flags: {
      apiBaseUrlMap: process.env.API_BASE_URL_MAP,
      configAbiFiles: process.env.CONFIG_ABI_FILES,
      configurations: process.env.CONFIG_FILES,
      language: langFromURL(url, window.navigator.language),
      protoDocJson: process.env.SERVICE_CONFIG,
      path: stripHref(window.location.href),
      targetNetwork: targetNetwork,
    },
  });

  function findParent(tagname, el) {
    if (!el) {
      return null;
    }

    if ((el.nodeName || el.tagName).toLowerCase() === tagname.toLowerCase()) {
      return el;
    }

    return findParent(tagname, el.parentNode);
  }

  document.addEventListener('click', (e) => {
    e = e || event;
    var from = findParent('a', e.target || e.srcElement);

    if (from && from.href && !from.rel && !e.metaKey) {
      history.pushState({}, '', buildHref(from.href));
      e.preventDefault();
      app.ports.onUrlChange.send(from.href);
    }
  });

  // Inform app of browser navigation (the BACK and FORWARD buttons)
  let statePopped = false;
  window.addEventListener('popstate', () => {
    statePopped = true;
    app.ports.onUrlChange.send(location.href);
  });

  app.ports.log.subscribe((msg) => {
    console.error(msg);
  });

  app.ports.scrollTop.subscribe(() => {
    if (!statePopped) {
      window.scrollTo(0, 0);
    } else {
      statePopped = false;
    }
  });

  let postRender = window.requestAnimationFrame;

  app.ports.setPageMeta.subscribe(([[title, metaDescription], [metaImageUrl, favicon]]) => {
    document.title = title;
    document.querySelector("link[rel='shortcut icon']").setAttribute('href', favicon);

    document.querySelector("meta[property='og:title']").setAttribute('content', title);

    document.querySelector("meta[name='description']").setAttribute('content', metaDescription);
    document.querySelector("meta[property='og:description']").setAttribute('content', metaDescription);
    document.querySelector("meta[property='og:image']").setAttribute('content', metaImageUrl);
  });

  app.ports.copy.subscribe((text) => {
    const el = document.createElement('textarea');
    el.value = text;
    el.setAttribute('readonly', '');
    el.style = { position: 'absolute', left: '-9999px' };
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  });

  app.ports.handleImageError.subscribe(({ elementId, imgSrc }) => {
    const element = document.getElementById(elementId);

    if (element) {
      console.warn('Image could not be loaded: ', element);

      element.src = imgSrc;
    }
  });
});
